/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Select } from "@mui/base";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import React from "react";
import FormHelperText from '@mui/material/FormHelperText';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import axios from "axios";

class Hero extends React.Component {

  constructor() {
        super();
        this.state = {
            sortBy: "hot",
            t: null,
            error: false,
            newTab: false,
            nsfwURL:"",
            sfwURL: ""
        };
        this.setUrls = this.setUrls.bind(this)
    }

  async setUrls(){
    const URL = 'https://api.alwayshello.com/reddit-runner/rand?nsfw='
    const REDDIT_URL = "https://www.reddit.com"
    try{
      var resp = await axios.get(URL + 1)
      var nsfwRedirectUrl = REDDIT_URL + resp?.data?.url + this.state.sortBy
      resp = await axios.get(URL + 0)
      var sfwRedirectUrl = REDDIT_URL + resp?.data?.url + this.state.sortBy
      this.setState({nsfwURL: nsfwRedirectUrl})
      this.setState({sfwURL: sfwRedirectUrl})
    }catch (err) {
      this.setState({error: true})
    }
  }

  componentDidUpdate(prevProps, prevState){
    const requiresDate = ["top", "controversial"]
    if (prevState.t !== this.state.t){
      if (requiresDate.includes(this.state.sortBy) && this.state.t){
        this.setState({nsfwURL: this.state.nsfwURL + `?t=${this.state.t}`})
        this.setState({sfwURL: this.state.sfwURL + `?t=${this.state.t}`})
      }
    }
  }

  componentDidMount(){
    this.setUrls()
  }

  render() {
    return (
      <>
        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default" style={{height: "100vh"}}>
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <p className="special-font">RedditRand</p>
                    <div className="btn-wrapper">
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0"
                        color="default"
                        href={this.state.sfwURL}
                        size="lg"
                      >
                        <span className="btn-inner--text">Rand</span>
                      </Button>{" "}
                      <Button
                        className="btn-icon mb-3 mb-sm-0"
                        color="github"
                        size="lg"
                        href={this.state.nsfwURL}
                      >
                        <span className="btn-inner--text">
                          <span className="text-warning mr-1">RandNSFW</span>
                        </span>
                      </Button>
                    </div>
                    <div>
                      <div style={{paddingTop: "1em", display: "none"}}>
                        <label htmlFor="cars" style={{color: "white", paddingRight:"1em"}}>
                          <strong>Sort By:</strong>
                        </label>
                        <select id="cars" defaultValue={this.state.sortBy} onChange={(e) => this.setState({sortBy: e.target.value})}
                        style={{border: "none", borderRadius: "5px"}}
                        >
                          <option value="hot">Hot</option>
                          <option value="new">New</option>
                          <option value="top">Top</option>
                          <option value="rising">Rising</option>
                          <option value="controversial">Controversial</option>
                        </select>

                        { (["top", "controversial"].includes(this.state.sortBy)) 
                        ?
                        <>
                        {"  "}
                          <select id="cars" defaultValue={this.state.t} onChange={(e) => this.setState({t: e.target.value})}
                          style={{border: "none", borderRadius: "5px"}}>
                            <option value="hour">Hour</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                            <option value="all">All Time</option>
                          </select>
                        </>
                        : <></>
                        }
                      </div>
                      <Button style={{marginTop: "1em"}} className="btn-1 ml-1" color="default" type="button" onClick={() => this.setUrls()} >
                        Get New Links
                      </Button>
                  </div>
                    <p className="lead text-white">
                      A recreation of the reddit rand and randnsfw feature. Please consider buying me a coffee if you enjoy this feature.
                      <br/>
                      <Button style={{marginTop: "1em"}} className="btn-1 ml-1" color="warning" type="button" href="https://www.buymeacoffee.com/redditrand" >
                        Buy Me A Coffee
                      </Button>
                    </p>
                    
                  </Col>
                </Row>
              </div>
            </Container>
              <div className="mt-5" style={{position: "absolute", bottom: "1em", margin: "auto", width: "100%", textAlign: "center"}}>
                <small className="text-white font-weight-bold mb-0 mr-2">
                UI Template by
                </small>
                <img
                  alt="..."
                  className="ml-1"
                  style={{ height: "28px" }}
                  src={require("assets/img/brand/creativetim-white-slim.png")}
                />
              </div>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
